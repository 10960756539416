/**
 * Language class
 */
export default class Language {
    /**
     * Constructor
     * @param {Object} obj Phrases
     */
    constructor(obj) {
        this.obj = obj;
        this.phrase = function(id) {
            return this.obj[id.toLowerCase()];
        };
    }
}