import SelectionListOption from "./SelectionListOption";

/**
 * Class Dynamic Selection List
 */
export default class SelectionList extends HTMLInputElement {
    containerClass = "d-table";
    rowClass = "d-table-row";
    cellClass = "d-table-cell";

    /**
     * Options
     * @type {SelectionListOption[]}
     */
    options = [];

    /**
     * Specify observed attributes so that attributeChangedCallback will work
     */
    static get observedAttributes() {
        return ["class"];
    }

    /**
     * Constructor
     */
    constructor() {
        super();
    }

    /**
     * Connected
     */
    connectedCallback() {
        let value = this.getAttribute("value") || "",
            values = this.multiple ? value.split(ew.MULTIPLE_OPTION_SEPARATOR) : [value];
        for (let val of values)
            this.add(val, "", true);
    }

    /**
     * Target element id
     */
    get targetId() {
        return this.getAttribute("data-target");
    }

    /**
     * Target
     */
    get target() {
        return this.parentNode.querySelector("#" + this.targetId);
    }

    /**
     * Template id
     */
    get templateId() {
        return this.getAttribute("data-template");
    }

    /**
     * Template
     */
    get template() {
        return this.parentNode.querySelector("#" + this.templateId);
    }

    /**
     * Input element id (for AutoSuggest)
     */
    get inputId() {
        return this.getAttribute("data-input");
    }

    /**
     * Input element (for AutoSuggest)
     */
    get input() {
        return this.parentNode.querySelector("#" + this.inputId);
    }

    /**
     * Is list
     */
    get list() {
        return this.options;
    }

    /**
     * Number of columns
     */
    get columns() {
        if (ew && ew.IS_MOBILE) {
            return 1;
        } else {
            let cols = this.getAttribute("data-repeatcolumn");
            return cols ? parseInt(cols, 10) : 1;
        }
    }

    /**
     * Layout
     */
    get layout() {
        let type = this.getAttribute("data-layout");
        return (type == "grid") ? type : "";
    }

    /**
     * Length
     */
    get length() {
        return this.options.length;
    }

    /**
     * Get selected index
     */
    get selectedIndex() {
        for (let option of this.options) {
            if (option.selected)
                return option.index;
        }
        return -1;
    }

    /**
     * Set selected index
     */
    set selectedIndex(index) {
        let option = this.options[index];
        if (option) {
            this.options.forEach(option => option.selected = false);
            option.selected = true;
            this.render();
        }
    }

    /**
     * Type
     */
    get type() {
        return this.getAttribute("data-type") || this.getAttribute("type");
    }

    /**
     * Multiple
     */
    get multiple() {
        if (this.hasAttribute("data-multiple")) {
            return this.getAttribute("data-multiple") != "0";
        } else {
            return this.type == "select-multiple";
        }
    }

    /**
     * Get value
     * @returns {string}
     */
    get value() {
        if (this.type == "select-one" || this.type == "select-multiple") {
            return this.values.join(ew.MULTIPLE_OPTION_SEPARATOR || ",");
        } else {
            return this.getAttribute("value");
        }
    }

    /**
     * Get value as array
     * @returns {string[]}
     */
    get values() {
        if (this.type == "select-one" || this.type == "select-multiple") {
            return Array.prototype.filter.call(this.options, option => option.selected).map(option => option.value);
        } else {
            let val = this.getAttribute("value");
            return val ? val.split(ew.MULTIPLE_OPTION_SEPARATOR || ",") : [];
        }
    }

    /**
     * Set value
     * @param {string|string[]} val
     */
    set value(val) {
        if (this.type == "select-one") {
            for (let option of this.options)
                option.selected = (option.value == val);
        } else if (this.type == "select-multiple") {
            let ar;
            if (Array.isArray(val)) { // Array
                ar = val.map(v => v ?? String(v));
            } else { // String
                val = val ?? String(val);
                ar = val ? val.split(ew.MULTIPLE_OPTION_SEPARATOR || ",") : [];
            }
            for (let option of this.options)
                option.selected = ar.includes(String(option.value));
        } else {
            this.setAttribute("value", val);
        }
        this.render();
    }

    /**
     * Add an option
     */
    add(value, text, selected) {
        let option = new SelectionListOption(value, text, selected),
            index = this.options.findIndex(option => option.value == value);
        if (index > -1)
            this.options[index] = option;
        else
            this.options.push(option);
    }

    /**
     * Remove an option
     */
    remove(index) {
        let option = this.options[index];
        if (option)
            this.options.splice(index, 1);
    }

    /**
     * Remove all options
     */
    removeAll() {
        this.options.splice(0);
    }

    /**
     * Clear selection
     */
    clear() {
        for (let option of this.options)
            option.selected = false;
        this.render();
    }

    /**
     * Get random number
     */
    getRandom() {
        return Math.floor(Math.random() * (999999 - 100000)) + 100000;
    }

    /**
     * Trigger change event
     */
    triggerChange() {
        const event = new Event("change", {
            view: window,
            bubbles: true,
            cancelable: false
        });
        this.dispatchEvent(event);
    }

    /**
     * Check if invalid
     */
    isInvalid(className) {
        return /\bis-invalid\b/.test(className);
    }

    /**
     * Check class
     */
    attributeChangedCallback(name, oldValue, newValue) {
        if (name == "class") {
            if (this.targetId && this.isInvalid(oldValue) != this.isInvalid(newValue)) { // "is-invalid" toggled
                let target = document.getElementById(this.targetId),
                    inputs = target.querySelectorAll("input"),
                    isInvalid = this.isInvalid(newValue);
                Array.prototype.forEach.call(inputs, input => input.classList.toggle("is-invalid", isInvalid));
            }
        }
    }

    /**
     * Render checkbox or radio in the target element
     */
    render() {
        let target = this.target,
            template = this.template;
        if (!target || !template || !this.list)
            return;

        // Clear the target
        while (target.firstChild)
            target.removeChild(target.firstChild);

        // Render
        target.style.cursor = "wait";
        let self = this,
            content = template.content,
            cols = this.columns || 1,
            tbl = document.createElement("div"),
            cnt = this.length,
            radioSuffix = "_" + this.getRandom(),
            isInvalid = this.classList.contains("is-invalid"),
            row;
        if (this.layout == "grid") {
            this.containerClass = "container";
            this.rowClass = "row";
            this.cellClass = "col";
        }
        tbl.className = this.containerClass + " ew-item-container";
        target.append(tbl);
        try {
            let options = this.options.filter(opt => opt.value);
            options.forEach((option, i) => {
                let clone = content.cloneNode(true),
                    input = clone.querySelector("input"),
                    label = clone.querySelector("label"),
                    suffix = "_" + this.getRandom(); // Make sure the id is unique
                input.name = input.name + (input.type == "radio" ? radioSuffix : suffix);
                input.id = input.id + suffix;
                input.value = option.value;
                input.setAttribute("data-index", i);
                input.checked = option.selected;
                if (isInvalid)
                    input.classList.add("is-invalid");
                input.addEventListener("click", function() {
                    let index = parseInt(this.getAttribute("data-index"), 10);
                    if (self.type == "select-one") {
                        for (let option of self.options)
                            option.selected = false;
                    }
                    self.options[index].selected = this.checked;
                    self.setAttribute("value", self.value);
                    self.triggerChange();
                })
                label.innerHTML = option.text;
                label.htmlFor = input.id;
                let cell = document.createElement("div");
                cell.className = this.cellClass;
                cell.appendChild(clone);
                if (i % cols == 0) {
                    row = document.createElement("div");
                    row.className = this.rowClass;
                }
                row.append(cell);
                if (i % cols == cols - 1) {
                    tbl.append(row);
                } else if (i == cnt - 1) { // Last
                    for (let j = (i % cols) + 1; j < cols; j++) {
                        let c = document.createElement("div");
                        c.className = this.cellClass;
                        row.append(c);
                    }
                    tbl.append(row);
                }
            });
            this.setAttribute("value", this.value);
        } finally {
            target.style.cursor = "default";
        }
    }

    /**
     * Set focus
     */
    focus() {
        if (this.list) {
            this.target?.querySelector("input")?.focus();
        } else {
            super.focus();
        }
    }
}
